var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","top":"","right":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-plus ")])],1)]}}])},[_c('v-list',_vm._l((_vm.EventType),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.create(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{attrs:{"headers":[
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Typ', value: 'type', sortable: false },
        { text: 'Start', value: 'startDate', sortable: true },
        { text: 'Ende', value: 'endDate', sortable: true }
      ],"items":_vm.events,"mobile-breakpoint":"0","sort-by":"startDate","sort-desc":"","search":_vm.filter,"items-per-page":15},on:{"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.EventType.find(function (t) { return t.value === item.type; }).text)+" ")]}},{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.endDate))+" ")]}}])})],1),_c('event',{attrs:{"id":_vm.dialog.id},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }