var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog',{attrs:{"value":_vm.Event.name,"id":_vm.id,"label":"Name"},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'name', value);
}}},[_c('v-text-field',{attrs:{"value":_vm.Event.name,"label":"Name","readonly":""}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('base-edit-dialog-date',{attrs:{"value":_vm.Event.startDate,"id":_vm.id,"label":"Beginn","dateformat":"DD.MM.YYYY"},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'startDate', value, 'Date');
}}},[_c('v-text-field',{attrs:{"value":_vm._f("dateformat")(_vm.Event.startDate,'DD.MM.YYYY'),"label":"Beginn","readonly":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("far fa-calendar-day")])]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('base-edit-dialog-date',{attrs:{"value":_vm.Event.endDate,"id":_vm.id,"label":"Ende","dateformat":"DD.MM.YYYY"},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'endDate', value, 'Date');
}}},[_c('v-text-field',{attrs:{"value":_vm._f("dateformat")(_vm.Event.endDate,'DD.MM.YYYY'),"label":"Ende","readonly":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("far fa-calendar-day")])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }